<template>
  <div class="register">
    <b-container id="form">
      <b-row>
        <b-col offset-md="3" cols="12" md="6">
          <b-form @submit="onSubmit">
            <b-row>
              <b-col>
                <b-form-group
                  id="input-first-name"
                  label="Vorname:"
                  label-for="first-name"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col>
                    <b-form-input
                      id="first-name"
                      size="sm"
                      v-model="form.first_name"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte füllen Sie dieses Feld aus."
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="last-name"
                  label="Nachname:"
                  label-for="last-name"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col>
                    <b-form-input
                      id="last-name"
                      size="sm"
                      v-model="form.last_name"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte füllen Sie dieses Feld aus."
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-birthdate"
                  label="Geburtsdatum:"
                  label-for="birthdate"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <b-form-input
                      id="birthdate"
                      size="sm"
                      v-model="form.birthdate"
                      placeholder="Bitte wählen Sie ein Datum aus"
                      type="date"
                      :min="minDate"
                      :max="maxDate"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte füllen Sie dieses Feld aus"
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-street"
                  label="Straße:"
                  label-for="street"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <b-form-input
                      id="street"
                      size="sm"
                      v-model="form.street"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte füllen Sie dieses Feld aus."
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-street_number"
                  label="Hausnummer:"
                  label-for="street_number"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <b-form-input
                      id="street_number"
                      size="sm"
                      v-model="form.street_number"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte füllen Sie dieses Feld aus."
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-zipcode"
                  label="Postleitzahl:"
                  label-for="zipcode"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <b-form-input
                      id="zipcode"
                      size="sm"
                      v-model="form.zipcode"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte füllen Sie dieses Feld aus."
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-town"
                  label="Stadt:"
                  label-for="town"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <b-form-input
                      id="town"
                      size="sm"
                      v-model="form.town"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte füllen Sie dieses Feld aus."
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-phone"
                  label="Telefon:"
                  label-for="phone"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <b-form-input
                      id="phone"
                      size="sm"
                      v-model="form.phone_number"
                      required
                      :disabled="form_disabled"
                      pattern="[0-9].+"
                      data-validation-text="Bitte geben Sie eine gültige Telefonnummer im folgenden Format: '02514920' ein"
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-mail"
                  label="Ihr @muenster.de-Hauptaccount:"
                  label-for="input-muenster_mail"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <div class="input-group-append">
                      <b-form-input
                        id="muenster_mail"
                        size="sm"
                        v-model="form.muenster_mail"
                        required
                        :disabled="form_disabled"
                        :state="mailValidation"
                        data-validation-text="Bitte füllen Sie dieses Feld aus."
                      ></b-form-input>
                      <span
                      class="input-group-text form-control-sm"
                      style="font-size: 0.875rem"
                      id="basic-addon2"
                      >@muenster.de</span
                      >
                    </div>
                  </b-col>
                  <b-col>
                    <b-form-invalid-feedback v-if="this.form.muenster_mail.length > 0" :state="mailValidation">
                      Die Adresse darf nur Buchstaben, Zahlen, Punkte und Bindestriche enthalten.
                    </b-form-invalid-feedback>
                    <b-form-text id="password-hint"
                      >Achtung: Die Anmeldung mit einer Alias-Adresse ist nicht
                      möglich!</b-form-text
                    >
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-mail"
                  label="Alternative E-Mail Adresse für Rückfragen"
                  label-for="input-mail"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <div class="input-group-append">
                      <b-form-input
                        id="private_mail"
                        size="sm"
                        v-model="form.private_mail"
                        :disabled="form_disabled"
                      ></b-form-input>
                    </div>
                  </b-col>
                  <b-col>
                    <b-form-text id="send-hint"
                      >Der Versand des neuen Passworts erfolgt per Post und dauert ca. 4-5 Tage</b-form-text
                    >
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-notes"
                  label="Angaben zum Account (alte Postanschriften oder ähnliches, falls vorhanden):"
                  label-for="input-notes"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <div class="input-group-append">
                      <b-form-textarea
                        id="notes"
                        size="sm"
                        v-model="form.notes"
                        rows="8"
                        :disabled="form_disabled"
                        data-validation-text="Bitte füllen Sie dieses Feld aus."
                      ></b-form-textarea>
                    </div>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="this.form.captcha_id != ''">
              <b-col>
                <img :src="get_image_captcha_url()" />
              </b-col>
              <b-col>
                <audio
                  id="audio_with_controls"
                  controls
                  :src="get_audio_captcha_url()"
                  type="audio/mp3"
                >
                  Ihr Browser kann dieses Tondokument nicht wiedergeben.<br />
                </audio>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <a class="text-center" v-on:click="get_captcha_id" style="cursor: pointer"
                  >Captcha neu laden</a
                >
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <img src="" />
                <b-form-group
                  id="input-captcha"
                  label="Captcha:"
                  label-for="input-captcha"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <b-form-input
                      id="captcha"
                      size="sm"
                      v-model="form.captcha_solution"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte füllen Sie dieses Feld aus."
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-checkbox
                  id="agreement"
                  v-model="form.agreement"
                  name="agreement"
                  required
                  :disabled="form_disabled"
                  data-validation-text="Bitte bestätigen Sie, dass Sie der Inhaber der Adresse sind."
                >
                  <b-col>
                    Ich bestätige, dass mir die oben genannte @muenster.de-Adresse gehört.
                  </b-col>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-checkbox
                  id="hint"
                  v-model="form.hint"
                  name="hint"
                  required
                  :disabled="form_disabled"
                  data-validation-text="Bitte bestätigen Sie die Wartezeit"
                >
                  <b-col>
                    <b>Ich nehme zur Kenntnis, dass die Bearbeitung der Anfrage mindestens drei bis fünf Werktage dauert. Eine erneute Anfrage verlängert den Bearbeitungszeitraum.</b>
                  </b-col>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-alert v-model="msg_show" v-bind:variant="msg_type">
                  <h4>{{ msg_text }}</h4>
                </b-alert>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col>
                <b-button type="submit" size="sm" variant="primary" :disabled="!mailValidation || form_disabled">
                  Antrag abschicken</b-button
                >
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Register",
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);
    minDate.setYear(minDate.getFullYear() - 120);
    const maxDate = new Date(today);
    return {
      minDate: minDate.toISOString(),
      maxDate: maxDate.toISOString(),
      form: {
        first_name: "",
        last_name: "",
        birthdate: "",
        street: "",
        street_number: "",
        zipcode: "",
        phone_number: "",
        private_mail: "",
        muenster_mail: "",
        captcha_id: "",
        captcha_solution: "",
        agreement: false,
      },
      msg_text: "",
      msg_show: false,
      msg_type: "",
      form_disabled: false,
    };
  },
  computed: {
    mailValidation() {
      return this.form.muenster_mail !== null && this.form.muenster_mail.match(/^[a-zA-Z0-9._-]+$/) !== null && this.form.muenster_mail.match(/^[a-zA-Z0-9._-]+$/).length > 0;
    },
  },
  mounted() {
    this.get_captcha_id();
    var elements = document.getElementsByTagName("INPUT");
    for (var i = 0; i < elements.length; i++) {
      elements[i].oninvalid = function(e) {
        e.target.setCustomValidity("");
        if (!e.target.validity.valid) {
          e.target.setCustomValidity(e.target.dataset.validationText);
        }
      };
      elements[i].oninput = function(e) {
        e.target.setCustomValidity("");
      };
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      axios
        .post("/api/password", JSON.stringify(this.form))
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.msg_type = "success";
          this.msg_text =
            "Die Daten wurden erfolgreich übermittelt. Bitte beachten Sie die Bearbeitungszeit, eine erneute Anfrage ist nicht erforderlich.";
          this.msg_show = true;
          this.form_disabled = true;
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          if (error != null) {
            this.msg_type = "warning";
            this.msg_show = true;
            this.msg_text = error.response.data;
            this.get_captcha_id();
          }
          //console.log(error);
        });
    },
    get_captcha_id() {
      axios.get("/api/captcha").then((response) => (this.form.captcha_id = response.data));
    },
    get_image_captcha_url() {
      return "/api/captcha/" + this.form.captcha_id + ".png";
    },
    get_audio_captcha_url() {
      return "/api/captcha/" + this.form.captcha_id + ".wav";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
a {
  color: #005b79;
  text-decoration: underline;
}
.form {
  margin: 40px;
}
.info p {
  text-align: left;
}

.modal-dialog {
  max-width: 80%;
}

.btn-primary {
  background-color: #005b79;
  border-color: #005b79;
}

h4 {
  font-size: 1.1rem;
}

.register {
  padding-bottom: 50px;
  margin-bottom: 50px;
}

#password-hint,
#send-hint {
  font-weight: bold;
  text-align: left;
}
</style>
